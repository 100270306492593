
  #mainNav {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
  }

 
  #mainNav .navbar-toggler {
    padding: 0.75rem;
    font-size: 0.75rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    font-weight: 700;
    color: #000!important;
  }

  .nav-item #num{
    font-size: 90px!important;
  }

  #mainNav .navbar-brand {
    color: #ffc800;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
  }
  #mainNav .navbar-brand img {
    height: 3rem!important;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.95rem;
    color: rgb(0, 0, 0);
    letter-spacing: 0.0625em;
    text-align: center;
  }
  #mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: #ffc800;
  }
  
  @media (min-width: 992px) {
    #mainNav {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border: none;
      background-color: #fff;
      transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
    }
    #mainNav .navbar-brand {
      font-size: 1.5em;
      transition: font-size 0.3s ease-in-out;
    }
    #mainNav .navbar-brand img {
      height: 2rem;
      transition: height 0.3s ease-in-out;
    }
    #mainNav.navbar-shrink {
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: #ffffff;
    }
    #mainNav.navbar-shrink .navbar-brand {
      font-size: 1.25em;
    }
    #mainNav.navbar-shrink .navbar-brand svg,
  #mainNav.navbar-shrink .navbar-brand img {
      height: 1.5rem;
    }
    #mainNav .navbar-nav .nav-item {
      margin-right: 1rem;
    }
    #mainNav .navbar-nav .nav-item:last-child {
      margin-right: 0;
    }
  }