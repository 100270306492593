

header.hero {
    padding-top: 10.5rem;
    padding-bottom: 6rem;
    text-align: center;
    color: #000;
    background-image: url("../img/header-bg.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
  }
  header.hero .hero-subheading {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.5rem;
    margin-bottom: 25px;
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  header.hero .hero-heading {
    font-size: 3.25rem;
    font-weight: 700;
    line-height: 3.25rem;
    margin-bottom: 2rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .hero-logo{
    margin-bottom: 3rem;
  }
  
  @media (min-width: 768px) {
    header.hero {
      padding-top: 17rem;
      padding-bottom: 12.5rem;
    }
    header.hero .hero-subheading {
      font-size: 2.25rem;
      font-style: italic;
      line-height: 2.25rem;
      margin-bottom: 2rem;
    }
    header.hero .hero-heading {
      font-size: 4.5rem;
      font-weight: 700;
      line-height: 4.5rem;
      margin-bottom: 4rem;
    }
  }