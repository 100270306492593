@charset "UTF-8";


*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none!important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  
  line-height: 1.2;
}


a {
  color: #003193;
  text-decoration: underline;
}
a:hover {
  color: #cca000;
}


.dark-bg{
	background-color: #f8f9fa!important;
}


.btn {
  display: inline-block;
  font-weight: 400;
  font-size: 30px!important;
  font-family: 'Cormorant Garamond', serif;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 200, 0, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #003193;
  border-color: #003193;
}
.btn-primary:hover {
  color: #fff;
  background-color: #d9aa00;
  border-color: #cca000;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #d9aa00;
  border-color: #cca000;
  box-shadow: 0 0 0 0.25rem rgba(255, 208, 38, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #cca000;
  border-color: #bf9600;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 208, 38, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #003193;
  border-color: #003193;
}


.img-brand {
  height: 2.75rem;
}

@media (min-width: 768px) {
  section {
    padding: 9rem 0;
  }
}

.page-section {
  padding: 6rem 0;
}

section#contact .section-heading {
  color: #fff;
}
section#contact form#contactForm .form-group {
  margin-bottom: 1.5rem;
}
section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
  padding: 1.25rem;
}
section#contact form#contactForm .form-group input.form-control {
  height: auto;
}
section#contact form#contactForm .form-group-textarea {
  height: 100%;
}
section#contact form#contactForm .form-group-textarea textarea {
  height: 100%;
  min-height: 10rem;
}
section#contact form#contactForm p.help-block {
  margin: 0;
}
section#contact form#contactForm .form-control:focus {
  border-color: #003193;
  box-shadow: none;
}
section#contact form#contactForm ::-webkit-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
section#contact form#contactForm :-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
section#contact form#contactForm ::-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}
section#contact form#contactForm :-ms-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}



/*services*/



.site-heading h2 {
  display: block;
  font-family: 'Cormorant Garamond', serif;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 3rem;
}

.site-heading h2 span {
  color: #003193;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: none;
  z-index: 1;
}

.site-heading h4::before {
  background: #003193 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.features-items{
  margin-bottom: 3rem;
}


.features-items,
.features-items .items-box {
  overflow: hidden;
}



.features-area .features-items .col-md-5, 
.features-area .features-items .col-md-7 {
  display: table-cell;
  float: none;
  vertical-align: middle;
}

.features-area .features-items.reversed .col-md-5, 
.features-area .features-items.reversed .col-md-7 {
  display: inline-block;
  float: left;
}

.features-area .features-items.reversed .info-box {
  float: right;
}

.features-area .features-items .item {
  padding: 15px 30px;
}

.features-area.item-full .features-items .item {
  padding: 30px;
}

.features-area .features-items .item h4 {
  position: relative;
}

.features-area.bottom-small {
  padding-bottom: 25px;
}

.features-area.default-padding.bottom-none {
  padding-bottom: 30px;
}

.features-area .item .icon {
  margin-bottom: 20px;
}


.features-area .item .info {
}

.img-service{
  top: 50%!important;
  margin-top: 1.5rem;
  height: 90px;
}

.features-area .item .icon  {
  border: solid 5px #E2C136 ;
  -moz-box-shadow: 0 0 10px #cccccc !important;
  -webkit-box-shadow: 0 0 10px #cccccc !important;
  -o-box-shadow: 0 0 10px #cccccc !important;
  box-shadow: 0 0 10px #cccccc !important;
  display: inline-block !important;
  font-size: 30px !important;
  border-radius: 50%;
  height: 200px ;
  position: relative!important;
  padding: 20px;
  text-align: center!important;
  width: 200px;
  z-index: 1!important;
}

// .features-area .item .icon:hover  {
//   background-color: #E2C136;
// }

.features-area .features-items .items-box img {
  background: transparent!important;
}

.features-area .item .icon {
  margin-bottom: 25px!important;
}

.features-area .features-items.icon-solid img {
  border-radius: inherit!important;
  -moz-box-shadow: 0 0 10px #a18282!important;
  -webkit-box-shadow: 0 0 10px #cccccc!important;
  -o-box-shadow: 0 0 10px #cccccc!important;
  box-shadow: 0 0 10px #cccccc!important;
  color: #003193!important;
  display: inline-block!important;
  font-size: 50px!important;
  height: 80px!important;
  line-height: 80px!important;
  position: relative!important;
  text-align: center!important;
  width: 80px!important;
}


.features-area .item .info h4 {
    font-weight: 600;
    text-transform: none;
    font-size: 20px;
}
.features-area .item .info p {
  margin: 0;
}

.features-area .features-items.less-icon .items-box.inc-cell .item .info {
  padding-left: 0;
}

.features-area .features-items .items-box.inc-cell .item .info a {
  color: #666666;
  display: inline-block;
  margin-top: 15px;
  text-transform: none;
}

.features-area .features-items .items-box.inc-cell .item .info a:hover {
  color: #003193;
}

/* Slider */

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}


  
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-img{
    margin-right: 2rem!important;
    margin-top: 0.5rem;
  }


  /*contact*/

  .blue-text{
    color: #003193;
  }

  .container-map{
    background: linear-gradient( rgba(0, 0, 0, 0.345), rgba(0, 0, 0, 0.31) ), url("../img/map.png");
    -webkit-background-size: cover;
    background-size: cover;
	background-position: center center;
	margin-top: -2.8rem!important;
}


.information-container {
  background: #000000;
  background: -webkit-linear-gradient(45deg, #434343, #000000);
  background: linear-gradient(45deg, #434343, #000000);

  background-size: cover;
  padding: 60px 0;
}

.inner-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  overflow: hidden;
}

.section-title {
  text-align: center;
  color: #ddd;
  text-transform: uppercase;
  font-size: 30px;
}

.border {
  width: 160px;
  height: 2px;
  margin: 40px auto;
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-box {
  max-width: 33.33%;
  padding: 10px;
  text-align: center;
  color: #ddd;
  cursor: pointer;
}

.service-icon {
  display: inline-block;
  width: 70px;
  text-align: center!important;
  height: 70px;
  border: 3px solid #cb2d3e;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-top: 16px;
}

.service-icon i {
  line-height: 70px;
  transform: rotate(-45deg);
  font-size: 26px;
}

.service-box:hover .service-icon {
  border: solid 10px #E2C136;
  color: #ddd;
}

.service-title {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
.description {
  font-size: 14px;
}

@media screen and (min-width:1200px){
	.img-service{
	top: 50%!important;
    margin-top: 0.1rem!important;
	height: 20px!important;}

	.features-area .item .icon{
		border: 5px solid #e2c136;
		-o-box-shadow: 0 0 10px #ccc!important;
		box-shadow: 0 0 10px #ccc!important;
		display: inline-block!important;
		font-size: 30px!important;
		border-radius: 50%;
		height: 100px!important;
		position: relative!important;
		padding: 20px;
		text-align: center!important;
		width: 100px!important;
		z-index: 1!important;
	}

}

@media screen and (max-width:960px) {
  .service-box {
    max-width: 45%;
  }
}

@media screen and (max-width:768px) {
  .service-box {
    max-width: 50%;
  }
}

@media screen and (max-width:480px) {
  .service-box {
    max-width: 100%;
  }
}
}



/*animations*/


.animatable {
  
  /* initially hide animatable objects */
  visibility: hidden;
  
  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;   
  -moz-animation-play-state: paused;     
  -ms-animation-play-state: paused;
  -o-animation-play-state: paused;   
  animation-play-state: paused; 
}

/* show objects being animated */
.animated {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}



.animated-1 {
  visibility: visible;
  
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}


@-o-keyframes bounceIn {
	0% {
		opacity: 0;
		-o-transform: scale(.3);
	}

	50% {
		-o-transform: scale(1.05);
	}

	70% {
		-o-transform: scale(.9);
	}

	100% {
		opacity: 1;
		-o-transform: scale(1);
	}
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(.3);
	}

	50% {
		transform: scale(1.05);
	}

	70% {
		transform: scale(.9);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}
.animated.bounceIn {
	-webkit-animation-name: bounceIn;
	-moz-animation-name: bounceIn;
	-o-animation-name: bounceIn;
	animation-name: bounceIn;
}

/*brands we service*/

.sponsor-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 4rem;
	margin: 0 auto;
	max-width: 700px;
	margin-top: -6rem!important;
  }

  .sponsor-grid-1 {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	gap: 4rem;
	margin: 0 auto;
	max-width: 700px;
  }
  ul{
	  list-style-type: none;
  }
  
  li p {
	margin-top: 1rem;
	font-family: system-ui;
	text-align: center;
	list-style: none;
	list-style-type: none;
  }
  
  .sponsor img {
	aspect-ratio: 1 / 1;
	width: 100%;
	object-fit: contain;
  }
  
  .header-service{
	  font-family:  Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji ;
	  font-weight: 200;
	  text-decoration: none!important;
  }
  
  .box {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
	padding: 10px;
	width: 100%;
	min-height: 200px;
  }
